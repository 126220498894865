import dateTimeFormats from "constants/dateTimeFormats";

export const common = {
  ...dateTimeFormats.reduce(
    (acc, format) => {
      acc[format] = `{{date, ${format}}}`;

      return acc;
    },
    {} as Record<(typeof dateTimeFormats)[number], string>
  ),
  utc: "UTC",
  accountNumber: "Account number",
  expand: "Expand",
  sendATest: "Send a test",
  ccy: "Ccy",
  merchant: "Merchant",
  accounts_plural: "{{count}} accounts",
  accounts: "{{count}} account",
  dateUtc: "Date (UTC)",
  active: "Active",
  addedBy: "Added by",
  add: "Add",
  addNew: "Add new",
  Administration: "Administration",
  name: "Name",
  surname: "Surname",
  expandAll: "Expand all",
  collapseAll: "Collapse all",
  expirationDate: "Expiration date",
  status: "Status",
  refresh: "Refresh",
  all: "All",
  allCustomers: "All Customers",
  allowlist: "Allowlist",
  amount: "Amount",
  amountFrom: "Amount from",
  amountTo: "Amount to",
  Applications: "Applications",
  approveRequest: "Approve request",
  areYouSureYouWantToLeave: "Are you sure you want to leave this page?",
  auAccountNumber: "Au account number",
  authSecureInfo: "Secure, safe and trusted",
  "Bank accounts": "Bank accounts",
  bankName: "Bank name",
  banks: "Banks",
  blocklist: "Blocklist",
  bsb: "BSB",
  cancel: "Cancel",
  cancelRequest: "Cancel request",
  cannotLoadCustomerNames:
    "Cannot load customer names. Please try again later.",
  cashManagement: "Cash Management",
  accountName: "Account name",
  checkoutBranding: "Checkout Branding",
  circuitBreaker: "Circuit Breaker",
  clearFilters: "Clear filters",
  clearSearch: "Clear Search",
  "Client applications": "Client applications",
  comment: "Comment",
  configuration: "Configuration",
  configure: "Configure",
  confirm: "Confirm",
  connect: "Connect",
  continue: "Continue",
  copiedToClipboard: "Copied to clipboard",
  Copy: "Copy",
  country: "Country",
  create: "Create",
  close: "Close",
  maxCharacters: "Max. characters: {{count}}",
  applyFilters: "Apply",
  beforeYouCloseDownTheFilter:
    "Before you close down the filter, do you want to leave without applying your filters?",
  CURRENCY: "{{data, CURRENCY}}",
  currency: "Currency",
  currentSecretKeyWillBeInvalidated:
    "Current secret key will be invalidated immediately.",
  customer: "Customer",
  showLess: "Show Less",
  customerName: "Customer name",
  customers: "Customers",
  Dashboard: "Dashboard",
  date: "Date",
  deactivate: "Deactivate",
  declineRequest: "Decline request",
  Delete: "Delete",
  details: "Details",
  disable: "Disable",
  disabled: "Disabled",
  dontApply: "Don’t apply",
  doesNotBelongToSelectedCustomer:
    "{{resource}} does not belong to selected customer {{customerName}}",
  "Don't have an account yet?": "Don't have an account yet?",
  download: "Download",
  doYouWantCreateNewNotificationSecret:
    "Do you want to generate a new notification secret?",
  edit: "Edit",
  "Email address": "Email address",
  enable: "Enable",
  enabled: "Enabled",
  enterApplicationName: "Enter application's name",
  failingNotificationAlertEmail: "Failing notifications alert e-mail",
  Filter: "Filter",
  filters: "Filters",
  forbiddenError: "403 Error",
  generate: "Generate",
  generateNewSecret: "Generate a new secret",
  goBack: "Go back",
  https: "https://",
  iban: "IBAN",
  id: "ID",
  inactive: "Inactive",
  incorrect2FASecurityCode: "Incorrect 2FA security code.",
  KEYBOARD_DATE: "dd/MM/yyyy",
  leaveThisPage: "Leave this page",
  leaveWithoutSaving: "Leave without saving",
  Location: "Location",
  Login: "Login",
  manage: "Manage",
  "No results found": "No results found",
  noApplicationFound: "No application found",
  noResponseHasBeenReceived:
    "No response has been received to this test notification.",
  noResults: "No Results",
  settlements: "Settlements",
  noResultsFor:
    "There are no results for <strong>{{search}}</strong>, please check the spelling or try different keywords",
  noResultsForSelectedFilters: "There are no results for selected filters",
  moreFiltersText: "+ {{count}} more",
  "Not saved": "Not saved",
  configured: "Configured",
  notConfigured: "Not Configured",
  notFoundError: "404 Error",
  notifications: "Notifications",
  notificationSecret: "Notification secret",
  notificationsQueued: "Notifications queued",
  notificationStatus: "Notification status",
  noSearchResults: "No Search Results",
  Ok: "Ok",
  OK: "OK",
  filtersAppliedToThisView: "{{count}} filter applied to this view.",
  filtersAppliedToThisView_plural: "{{count}} filters applied to this view.",
  pageXofY: "Page %currentPage% of %totalPages%",
  Password: "Password",
  paymentAmountMustBeGreaterThan:
    "Payment amount must be greater than {{value}}",
  payments: "Payments",
  payouts: "Payouts",
  pixKey: "PIX Key",
  pleaseEnterAllRequiredFields: "Please, enter all required fields to continue",
  productionMode: "production mode",
  readLess: "Read Less",
  readMore: "Read More",
  refunds: "Refunds",
  RELATIVE_DATE_TIME: "{{date, RELATIVE_DATE_TIME}}",
  "Remember my email": "Remember my email",
  resend2FA: "Resend 2FA",
  resend2FASuccess: "2FA code has been resent",
  reset: "Reset",
  resetSearch: "Reset Search",
  Retry: "Retry",
  routingNumber: "Routing number",
  returnToDashboard: "Return to dashboard",
  riskRules: "Risk Rules",
  rowsPerPage: "Rows per page",
  sandboxMode: "sandbox mode",
  save: "Save",
  searchCustomers: "Search customers",
  secretHasBeenGenerated: "Secret has been successfully generated!",
  selectAll: "Select all",
  selectCustomer: "Select customer",
  selectFilter: "Select filter",
  selected_plural: "Selected {{count}} options",
  selected: "Selected {{count}} option",
  sendTestNotification: "Send a test notification",
  searchResults: "Search results",
  "Sign out": "Sign out",
  "Sign Up": "Sign Up",
  somethingWentWrong: "Something went wrong.",
  sorrySomethingWentWrong:
    "Sorry, something went wrong. Please try again later.",
  sorryPageNotFound: "Sorry, the page was not found",
  sortCode: "Sort code",
  startSearching: "Start searching",
  stayOnThisPage: "Stay on this page",
  submitRequest: "Submit request",
  swiftCode: "Swift code (BIC)",
  testNotifications: "Test Notifications",
  testOutcome: "Test outcome",
  theTestNotificationHasFailed:
    "The test notification has failed. Please review the error and try again.",
  thisTimestampInUTC:
    "This timestamp is in UTC. Your local time would be: {{fullDate}} {{hours}}",
  tooManyRequests: "Too many requests. Please, try again later.",
  transactions: "Transactions",
  tryAgain: "Try again",
  tryDifferentKeyword: "Try different keyword",
  typeMinimumCharacters: "Type minimum {{minSearchLength}} characters",
  twoFaCodeHasBeenSentToYourVoltEmail:
    "2FA code has been sent to your Volt e-mail.",
  twoFaSecurityCode: "2FA security code",
  type: "Type",
  typeHere: "Type here...",
  unexpectedError: "Unexpected error",
  updatedBy: "Updated by",
  "User management": "User management",
  Users: "Users",
  useTabAndArrowKeysToNavigate: "Use tab and arrow keys to navigate",
  value: "Value",
  viewError: "View error",
  viewing: "Viewing",
  viewMore: "View more",
  "Volt Merchant": "Volt Merchant",
  webhookUrl: "Webhook URL",
  notificationUrl: "Notification URL",
  urlToReceiveThePayoutInformation: "URL to receive the payout information",
  urlToReceiveTheCreditInformation: "URL to receive the credit information",
  urlToReceiveTheReportInformation: "URL to receive the report information",
  Workflows: "Workflows",
  xYCharactersRemaining_plural: "{{count}}/{{maxNumber}} characters remaining",
  xYCharactersRemaining: "{{count}}/{{maxNumber}} character remaining",
  youDoNotHaveNotifications:
    "You don’t have notifications set up right now, do talk to your system administrator if you think there is an issue or our support team",
  youDoNotHavePermissions: "You don't have permission to access.",
  youDontHavePermissionToPerformThisAction:
    "You don’t have a permission to perform this action.",
  youHaveUnsavedChanges: "You have unsaved changes.",
  yourOneTimePasswordHasExpired:
    "Your one time password has expired. Please resend 2FA.",
  youHaveUnsavedFilters: "You have unsaved filters",
};
