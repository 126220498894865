import type { PaymentNotification } from "models/Notification";
import type { NotificationSettings } from "models/NotificationSettings";
import type { Client } from "modules/applications/models/Client";
import type { Account } from "modules/bank-accounts/models/Account";
import type { Bank } from "modules/banks/models/banks";
import type { CashManagementAccount } from "modules/connect/models/CashManagementAccount";
import type { GetCustomerResponse } from "modules/customers/api/Customers";
import type { Customer } from "modules/customers/models/Customer";
import type { Payment } from "modules/payments/models/Payment";
import type { User } from "modules/user-management/models/User";

export const SUBJECT_ACCOUNT = "Account";
export const SUBJECT_BANK = "Bank";
export const SUBJECT_CLIENT = "Client";
export const SUBJECT_CHECKOUT = "Checkout";
export const SUBJECT_CLIENT_VERIFY_NOTIFICATIONS =
  "client-verify-notifications";
export const SUBJECT_DYNAMIC_BENEFICIARY = "DynamicBeneficiary";
export const SUBJECT_PAYMENT_NOTIFICATION = "PaymentNotification";
export const SUBJECT_USER = "User";
export const SUBJECT_NOTIFICATION_SETTINGS = "NotificationSettings";
export const SUBJECT_CUSTOMER = "Customer";
export const SUBJECT_PAYMENT = "Payment";
export const SUBJECT_REFUND = "Refund";
export const SUBJECT_PAYMENT_EXTERNAL_LINKS = "payment-external-links";
export const SUBJECT_CUSTOMER_PRODUCT_FLAGS = "CustomerProductFlags";
export const SUBJECT_CIRCUIT_BREAKER = "circuit-breaker";
export const SUBJECT_USER_PRODUCT_INTEREST = "user-product-interest";
export const SUBJECT_VERIFICATION = "verification";
export const SUBJECT_VERIFY = "verify";
export const SUBJECT_PAY_BY_LINK = "PayByLink";
export const SUBJECT_CLOSED_LOOP_PAYOUTS = "Payouts";
export const SUBJECT_OPEN_LOOP_PAYOUTS = "OpenLoopPayouts";
export const SUBJECT_WORKFLOWS = "Workflows";
export const SUBJECT_PAYOUTS_NOTIFICATIONS = "PayoutNotifications";
export const SUBJECT_CREDIT_NOTIFICATION_PREFERENCES =
  "CreditNotificationPreferences";

export const SUBJECT_CUSTOMER_COUNTRIES = "customer-banks";
export const SUBJECT_CUSTOMER_PUBLIC_KEYS = "customer-public-keys";
export const SUBJECT_CUSTOMER_PAYMENTS_PROVIDERS =
  "customer-payments-providers";
export const SUBJECT_CASH_MANAGEMENT = "cash-management";
export const SUBJECT_CASH_MANAGEMENT_ACCOUNT = "cash-management-account";
export const SUBJECT_CASH_MANAGEMENT_SETTLEMENT =
  "cash-management-settlement-notification";
export const SUBJECT_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION =
  "cash-management-settlement-notification";
export const SUBJECT_CASH_MANAGEMENT_PROVIDER_SETUP =
  "cash-management-provider-setup";

export type Subject =
  | Account
  | Bank
  | CashManagementAccount
  | Client
  | Customer
  | GetCustomerResponse
  | NotificationSettings
  | Payment
  | PaymentNotification
  | typeof SUBJECT_ACCOUNT
  | typeof SUBJECT_BANK
  | typeof SUBJECT_CASH_MANAGEMENT
  | typeof SUBJECT_CASH_MANAGEMENT_ACCOUNT
  | typeof SUBJECT_CASH_MANAGEMENT_PROVIDER_SETUP
  | typeof SUBJECT_CASH_MANAGEMENT_SETTLEMENT
  | typeof SUBJECT_CASH_MANAGEMENT_SETTLEMENT_NOTIFICATION
  | typeof SUBJECT_CHECKOUT
  | typeof SUBJECT_CIRCUIT_BREAKER
  | typeof SUBJECT_CLIENT
  | typeof SUBJECT_CLIENT_VERIFY_NOTIFICATIONS
  | typeof SUBJECT_CLOSED_LOOP_PAYOUTS
  | typeof SUBJECT_CREDIT_NOTIFICATION_PREFERENCES
  | typeof SUBJECT_CUSTOMER
  | typeof SUBJECT_CUSTOMER_COUNTRIES
  | typeof SUBJECT_CUSTOMER_PAYMENTS_PROVIDERS
  | typeof SUBJECT_CUSTOMER_PRODUCT_FLAGS
  | typeof SUBJECT_CUSTOMER_PUBLIC_KEYS
  | typeof SUBJECT_DYNAMIC_BENEFICIARY
  | typeof SUBJECT_NOTIFICATION_SETTINGS
  | typeof SUBJECT_OPEN_LOOP_PAYOUTS
  | typeof SUBJECT_PAY_BY_LINK
  | typeof SUBJECT_PAYMENT
  | typeof SUBJECT_PAYMENT_EXTERNAL_LINKS
  | typeof SUBJECT_PAYMENT_NOTIFICATION
  | typeof SUBJECT_PAYOUTS_NOTIFICATIONS
  | typeof SUBJECT_REFUND
  | typeof SUBJECT_USER
  | typeof SUBJECT_USER_PRODUCT_INTEREST
  | typeof SUBJECT_VERIFICATION
  | typeof SUBJECT_VERIFY
  | typeof SUBJECT_WORKFLOWS
  | User;

export const PROPERTY_ID = "id";
