import { getRouteWithPersistedFilterParams } from "modules/common/utils/getRouteWithFilterParams/getRouteWithFilterParams";

import {
  DEFAULT_FILTERS_CONFIGURATION,
  FILTERS_PERSISTED_KEY,
} from "./Filters";

import type { UUID } from "utils";

export const BASE_URL = "/transactions/payments";

export const PAGE_URLS = {
  list: `${BASE_URL}`,
  getList: () =>
    getRouteWithPersistedFilterParams(
      PAGE_URLS.list,
      FILTERS_PERSISTED_KEY,
      DEFAULT_FILTERS_CONFIGURATION
    ),
  details: `${BASE_URL}/:id`,
  notification: `${BASE_URL}/:id/notifications`,
  getLinkToDetails: (id: UUID) => `${BASE_URL}/${id}`,
  getLinkToNotifications: (id: UUID) => `${BASE_URL}/${id}/notifications`,
  getLinkToRefundList: (paymentId: UUID) => `${BASE_URL}/${paymentId}/refunds`,
  refundsList: `${BASE_URL}/:id/refunds`,
  getLinkToTransactionRefunds: (id: UUID) => `${BASE_URL}/${id}/refunds`,
  getLinkToRefundsFilteredByPayment: (paymentId: UUID) =>
    `/transactions/refunds?transactionId=${paymentId}`,
} as const;
