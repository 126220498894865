import { ACTION_DETAILS } from "models/role/action";
import {
  SUBJECT_CASH_MANAGEMENT,
  SUBJECT_CASH_MANAGEMENT_PROVIDER_SETUP,
} from "models/role/subject";
import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import { PAGE_URLS } from "../constants/PageUrls";
import { accountsRoutes } from "./accounts";
import { configurationRoutes } from "./configuration";
import { providersRoutes } from "./providers";

import type { Route } from "routes/RouteModel";

const Core = lazyRetryWithPreload(() => import("../pages/Core"), "Connect");

const Accounts = lazyRetryWithPreload(
  () => import("../pages/accounts/List"),
  "Accounts"
);

const Providers = lazyRetryWithPreload(
  () => import("../pages/provider-configs/List"),
  "Providers"
);

const Configuration = lazyRetryWithPreload(
  () => import("../pages/configuration/Configuration"),
  "Configuration"
);

export const connectRoutes: Route = {
  i18nName: "Connect",
  path: PAGE_URLS.core,
  component: Core,
  newTheme: true,
  hasNestedSwitch: true,
  showChildrenInSidebar: false,
  can: {
    do: ACTION_DETAILS,
    on: SUBJECT_CASH_MANAGEMENT,
  },
  children: [...accountsRoutes, ...providersRoutes, ...configurationRoutes],
};

export const coreRoutes: Route[] = [
  {
    i18nName: "Virtual accounts",
    path: PAGE_URLS.core,
    newTheme: true,
    component: Accounts,
    can: {
      do: ACTION_DETAILS,
      on: SUBJECT_CASH_MANAGEMENT,
    },
  },
  {
    i18nName: "Provider Configuration",
    path: PAGE_URLS.providers,
    component: Providers,
    newTheme: true,
    showChildrenInSidebar: false,
    can: {
      do: ACTION_DETAILS,
      on: SUBJECT_CASH_MANAGEMENT_PROVIDER_SETUP,
    },
  },
  {
    i18nName: "Configuration",
    path: PAGE_URLS.configuration,
    component: Configuration,
    can: {
      do: ACTION_DETAILS,
      on: SUBJECT_CASH_MANAGEMENT,
    },
    newTheme: true,
  },
];
