import { getPersistedFilterKey } from "modules/common/hooks/usePersistedFilters";

import { getLastTwoMonthsDateRange } from "../utils/getLastTwoMonthsDateRange";

const { start, end } = getLastTwoMonthsDateRange();

export const FILTERS_KEYS = {
  AMOUNT_MIN: "amountMin",
  AMOUNT_MAX: "amountMax",
  CURRENCY: "currency",
  COUNTRIES: "country",
  END_DATE: "endDate",
  LICENCE: "licence",
  START_DATE: "startDate",
  STATUS: "status",
  NO_BANK_CHOSEN: "noBankChosen",
} as const;

export const DEFAULT_FILTERS = {
  [FILTERS_KEYS.LICENCE]: [],
  [FILTERS_KEYS.STATUS]: [],
  [FILTERS_KEYS.CURRENCY]: [],
  [FILTERS_KEYS.COUNTRIES]: [],
  [FILTERS_KEYS.AMOUNT_MIN]: null,
  [FILTERS_KEYS.AMOUNT_MAX]: null,
  [FILTERS_KEYS.START_DATE]: start.toISODate(),
  [FILTERS_KEYS.END_DATE]: end.toISODate(),
  [FILTERS_KEYS.NO_BANK_CHOSEN]: false,
};

export const DEFAULT_FILTERS_CONFIGURATION = {
  [FILTERS_KEYS.LICENCE]: {
    array: true as const,
    defaultValue: [],
  },
  [FILTERS_KEYS.STATUS]: {
    array: true as const,
    defaultValue: [],
  },
  [FILTERS_KEYS.CURRENCY]: {
    array: true as const,
    defaultValue: [],
  },
  [FILTERS_KEYS.COUNTRIES]: {
    array: true as const,
    defaultValue: [],
  },
  [FILTERS_KEYS.START_DATE]: {
    array: undefined,
    defaultValue: start.toISODate(),
  },
  [FILTERS_KEYS.END_DATE]: {
    array: undefined,
    defaultValue: end.toISODate(),
  },
  [FILTERS_KEYS.NO_BANK_CHOSEN]: {
    array: undefined,
    defaultValue: false,
  },
  [FILTERS_KEYS.AMOUNT_MIN]: {
    array: undefined,
    defaultValue: null,
  },
  [FILTERS_KEYS.AMOUNT_MAX]: {
    array: undefined,
    defaultValue: null,
  },
};

export const FILTERS_PERSISTED_KEY = getPersistedFilterKey("new.payments");
